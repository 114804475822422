.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.default-background {
  background-color: #c4ffe8;
  color: rgb(2, 156, 148);
}

.happy-background {
  background-color: #fff787;
  color: #0400ff;
}
.meh-background {
  background-image: url('./zucc.jpeg');
  color: #ff0000;
  font-weight: bold;
  font-size: 80px;
}
.sad-background {
  background-color: #b0c2ff;
  color: #5980ff;
}
